import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll} from 'react-scroll';
import './Greenify.css';
import heroImg from './assets/img/Greenify/greenify-header-img.png';
import surveyImg1DarkMode from './assets/img/Greenify/survey-img1-dark-mode.png';
import surveyImg2DarkMode from './assets/img/Greenify/survey-img2-dark-mode.png';
import surveyImg1LightMode from './assets/img/Greenify/survey-img1-light-mode.png';
import surveyImg2LightMode from './assets/img/Greenify/survey-img2-light-mode.png';
import storyboardImg from './assets/img/Greenify/storyboard.png';
import sketchImg from './assets/img/Greenify/sketch.png';
import solutionImg1 from './assets/img/Greenify/solution-img1.png';
import solutionVideo1 from './assets/video/solution-video1.mp4';
import solutionVideo2 from './assets/video/solution-video2.mp4';

function Greenify() {

    const [theme, setTheme] = useState('dark');
    const [atBottom, setAtBottom] = useState(false);
    const [activeSection, setActiveSection] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);
  
    const setLightMode = () => {
      setTheme('light');
    };
  
    const setDarkMode = () => {
      setTheme('dark');
    };
  
    useEffect(() => {
      const handleScroll = () => {
        const sections = ['#background', '#research', '#ideate', '#solution', '#reflection'];
  
        let currentSection = '';
  
        sections.forEach(section => {
          const element = document.querySelector(section);
          const rect = element.getBoundingClientRect();
  
          // Detect when the section is in the viewport, at least partially visible
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            currentSection = section;
          } else if (rect.top < window.innerHeight && rect.bottom >= 0) {
            currentSection = section;
          }
        });
  
        setActiveSection(currentSection);
  
        // Check if user is at the bottom of the page
        if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
          setAtBottom(true);
        } else {
          setAtBottom(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      scroll.scrollToTop({
        duration: 400,
        smooth: 'easeInOutQuart',
      });
    };
  
    useEffect(() => {
      const hash = location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const offset = 40;
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    }, [location]);

    return (
        <div>
            <div class = "body">
                <div class = "main-content-hero">
                <div class = "hero-img">
                    <img src={heroImg} alt="Greenify Hero Image" />
                </div>
                <p class = "project-subtitle" id = "project-summary">Sustainable Design</p>
                <h1 class = "project-title">Guiding Eco-conscious Choices for Designers</h1>
                <div class = "project-details">
                    <p class = "project-description">For my final project in Sustainable Design, I proposed the idea of a Figma plugin that could assess the digital carbon footprint of design projects and recommend more eco-friendly alternatives. The tool would evaluate various design elements, such as color, images, and other components that affect energy consumption, providing suggestions to help designers make sustainable decisions.</p>
                    <div class = "project-tag-list">
                        <div class = "project-tag-row-1">
                            <div class = "project-tag-item" id = "timeline">
                                <p class = "tag-name">TIMELINE</p>
                                <p class = "tag-description">3 Weeks <br></br>(Fall 2023)</p>
                            </div>
                            <div class = "project-tag-item" id = "role">
                                <p class = "tag-name">ROLE</p>
                                <p class = "tag-description">Designer</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-2">
                            <div class = "project-tag-item" id = "collaborators">
                                <p class = "tag-name">COLLABORATORS</p>
                                <p class = "tag-description">Solo Project <br></br>Advised by Lining Yao</p>
                            </div>
                            <div class = "project-tag-item" id = "tools">
                                <p class = "tag-name">TOOLS</p>
                                <p class = "tag-description">Figma</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-3" id = "button-wrapper">
                            <a href="https://www.figma.com/proto/4FF3xQ0E8BR729stQCjYJA/Sustainable-Design?page-id=22%3A208&type=design&node-id=25-782&viewport=205%2C28%2C0.14&t=pVq9XRoJf3sbN2Ge-1&scaling=min-zoom&starting-point-node-id=25%3A782&mode=design" target="_blank" rel="noopener noreferrer">
                                <button class = "button-text">Prototype →</button>
                            </a>
                        </div>
                    </div>
                </div>
                </div>
                <div class = "left-column">
                    <nav>
                        <ul class = {`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
                        <li class = {`left-column-list-text ${activeSection === '#background' ? 'active' : ''}`}>
                            <Link to="/greenify#background" duration={500}>Background</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#research' ? 'active' : ''}`}>
                            <Link to="/greenify#research" duration={500}>Research</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#ideate' ? 'active' : ''}`}>
                            <Link to="/greenify#ideate" duration={500}>Ideate</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#solution' ? 'active' : ''}`}>
                            <Link to="/greenify#solution" duration={500}>Solution</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#reflection' ? 'active' : ''}`}>
                            <Link to="/greenify#reflection" duration={500}>Reflection</Link>
                        </li>
                        </ul>
                        {atBottom && (
                        <ul class = "shortcut-menu show">
                            <li class = "left-column-list-text" onClick = {scrollToTop} style = {{ cursor: 'pointer' }}>
                                Go to top ↑
                            </li>
                        </ul>
                        )}
                    </nav>
                    <div class = "theme-toggle">
                        <span onClick={setDarkMode} style={{ cursor: 'pointer', fontWeight: theme === 'dark' ? 'bold' : 'normal', color: theme === 'dark' ? 'inherit' : '#929292' }}>⏾ DARK</span>
                        <span style={{ margin: '0 10px' }}> / </span>
                        <span onClick={setLightMode} style={{ cursor: 'pointer', fontWeight: theme === 'light' ? 'bold' : 'normal', color: theme === 'light' ? 'inherit' : '#929292' }}>☼ LIGHT</span>
                    </div>
                </div>
                <div class = "main-content">
                    <section class = "content-section" id = "background">
                        <p class = "content-tag">• Background</p>
                        <div class = "section-content">
                            <p class = "section-subtitle">Motivation</p>
                            <p class = "body-text">There are an increasing number of digital services on the market that are aiming to harness technology to encourage sustainable behaviors. While the recent attention on green initiatives undoubtedly indicates a positive change, a question to ask is, <b>what is the environmental cost of creating such digital solutions?</b></p>
                            {/*<div id = "motivation-img">
                                <div id = "cost-and-benefit">
                                    <p id = "cost">Cost</p>
                                    <p id = "benefit">Benefit</p>
                                </div>
                                <div id = "line">―――――――――――――――――――――</div>
                                <div id = "triangle">△</div>
                            </div>*/}
                            <p class = "section-subtitle">Impact</p>
                            <p class = "body-text">Why is this problem important? In fact, the digital services that we use consume far more energy than we realize. Here are some eye-opening statistics: </p>
                            <div class = "impact-box-wrapper">
                                <div class = "impact-box-item">
                                    <div class = "impact-box-icon" id = "plane-icon">✈</div>
                                    <p class = "box-text">Digital carbon footprints are estimated to be about 3.7 percent of global CO₂ emissions, which is equivalent to the emissions of the entire aviation industry</p>
                                </div>
                                <div class = "impact-box-item">
                                    <div class = "impact-box-icon">⚘</div>
                                    <p class = "box-text">Globally, the average web page produces approximately 0.8 grams CO2 equivalent per pageview. For a website with 10,000 monthly page views, that's 102 kg CO2e per year. That is amount the amount of carbon that 5 trees absorb in a year.</p>
                                </div>
                                <div class = "impact-box-item">
                                    <div class = "impact-box-icon" id = "mail-icon">✉</div>
                                    <p class = "box-text">One less email sent by UK adults a day reduces emissions by 16,433 tons a year. This is equivalent to taking 3,334 diesel cars off the road.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "research">
                        <p class = "content-tag">• Research</p>
                        <div class = "section-content">
                            <p class = "italic-text"><span class="accent-text">Method One. Secondary Research</span></p>
                            <p class = "body-text">I began with a broad exploration, from gathering information about digital carbon footprints and how they are calculated. Then, I identified several key stakeholders involved in this issue: designers, engineers, business management, and the general public. Each of these groups had a unique role in addressing the problem, with specific actions they could take to mitigate their impact.</p>
                            <p class = "body-text">After completing my secondary research, I narrowed my focus to designers as the target audience for the following reasons: </p>
                            <p class = "body-text">1. I discovered that there were specific practices that designers could adopt to help reduce digital carbon footprints. </p>
                            <p class = "body-text">As a designer myself, I thought my background knowledge of the tools and processes used in design could help me see opportunities in incorporating sustainable practices into the design workflow.</p>
                            <p class = "italic-text"><span class="accent-text">Method Two. User Surveys</span></p>
                            <p class = "body-text">After identifying designers as my target audience, I focused my research on understanding more about their awareness of the environmental impacts of digital products, sustainable design practices, their willingness to adopt these practices, and the challenges they face in doing so. Due to the time constraints of this school project, I decided to distribute a survey and was able to get 32 responses from designers involved in creating digital products.</p>
                            <div class = "survey-img-wrapper">
                                <div class = "survey-img-list">
                                    <img id = "survey-img1" src={theme === 'light' ? surveyImg1LightMode : surveyImg1DarkMode} alt="Survey Result 1" />
                                    <img id = "survey-img2" src={theme === 'light' ? surveyImg2LightMode : surveyImg2DarkMode} alt="Survey Result 1" />
                                </div>
                            </div>
                            <p class = "italic-text">Insights</p>
                            <p class = "body-text"><span class="accent-text">1. There are potential intervention points within the design workflow where sustainable practices can be encouraged.</span></p>
                            <p class = "body-text"><span class="accent-text">2. Designers were generally unaware that their design choices could affect the energy consumption of digital products.</span></p>
                            <p class = "body-text"><span class="accent-text">3. While designers see the importance of sustainability, they lack the resources to evaluate the environmental impact of their designs and the steps to create more eco-friendly products.</span></p>
                        </div>
                    </section>
                    <section class = "content-section" id = "define">
                        <p class = "italic-text"><span class="accent-text">How might we let designers assess the energy efficiency of design choices and offer recommendations for more sustainable options?</span></p>
                    </section>
                    <section class = "content-section" id = "ideate">
                        <p class = "content-tag">• Ideate</p>
                        <p class = "italic-text"><span class="accent-text">Storyboarding</span></p>
                        <div class = "section-content">
                            <p class = "body-text">My idea was to utilize the form of a Figma plugin. If a tool to help make eco-conscious choices is embedded in the course of designing digital services, I thought that it would streamline the process for designers to evaluate the environmental impact of designs and apply recommended changes right away.</p>
                            <p class = "body-text">Building off the How Might We statement, I used storyboarding to visualize the steps of how designers would use the solution to resolve their problem. </p>
                            <p class = "body-text">My initial thought was to utilize the form of a <b>Figma plugin</b>. If a tool to help make eco-conscious choices is embedded in the course of designing digital services, I thought that it would streamline the process for designers to assess the energy efficiency of their design and apply changes right away. Initially, I only focused on the aspect of <b>assessing and recommending color</b>.</p>
                            <img id = "storyboard-img" src = {storyboardImg} alt = "Storyboard" />
                            <p class = "italic-text"><span class="accent-text">Sketching</span></p>
                            <p class = "body-text">After receiving feedback during class, I iterated my idea to include other design elements, such as <b>font, images, and videos</b> and hand-sketched some ideas of how I would organize the information.</p>
                            <img id = "sketch-img" src = {sketchImg} alt = "Sketch" />
                        </div>
                    </section>
                    <section class = "content-section" id = "solution">
                        <p class = "content-tag">• Solution</p>
                        <div class = "section-content">
                            <p class = "body-text"><span class="accent-text">Greenify, a Figma Plugin to help designers assess the energy efficiency of design choices and offer recommendations for more sustainable options.</span></p>
                            <img id = "solution-img1" src = {solutionImg1} alt = "Assess Design" />
                            <p class = "image-subtext">Assess design</p>
                            <div class = "solution-img-wrapper">
                                <div id = "solution-img2-wrapper">
                                    <video id = "solution-video1" autoPlay loop muted playsInline>
                                        <source src= {solutionVideo1} type="video/mp4" />
                                    </video>
                                    <p class = "image-subtext">Recommend Color</p>
                                </div>
                                <div id = "solution-img3-wrapper">
                                    <video id = "solution-video2" autoPlay loop muted playsInline>
                                        <source src= {solutionVideo2} type="video/mp4" />
                                    </video>
                                    <p class = "image-subtext">Optimize Image</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "reflection">
                        <p class = "content-tag">• Reflection</p>
                        <div class = "section-content">
                            <p class = "body-text">The learnings from this project has also impacted the way I design - to be more conscious about the design choices I make. An example is this website itself. I have designed this website to promote sustainability by reducing digital carbon footprints that could result from navigating the internet. Specifically, there are mainly three ways in which this is achieved.</p>
                            <p class = "body-text"><span class="accent-text">1. This website defaults to <b>dark mode</b>, which consumes less energy compared to a traditional white background—particularly on mobile devices, where OLED screens are widely used.</span></p>
                            <p class = "body-text"><span class="accent-text">2. The interface uses a <b>system typeface, Helvetica</b>, to eliminate the need for additional font downloads, thereby reducing energy consumption. Web-safe fonts also work across the widest range of devices and platforms, which improves access to information.</span></p>
                            <p class = "body-text"><span class="accent-text">3. I have tried to <b>minimized the use of images</b>, replacing most icons with <b>Unicode characters</b>, so that they can be displayed on any device without having to download image files.</span></p>
                        </div>
                    </section>
                </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                        <p class = "project-footer-subtitle">Generative Systems for Design</p>
                        <p class = "project-footer-title">Animating Static Album Covers to Bring Music to the Deaf</p>
                        <button class = "read-more-button" onClick={() => navigate('/subway')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                        <p class = "project-footer-subtitle">MHCI X University Hospitals of Cleveland</p>
                        <p class = "project-footer-title">Bridging the Gap Between Clinic & At-home Wound Care</p>
                        <button class = "read-more-button" onClick={() => navigate('/uhc')}>Read More →</button>
                    </div>
                </div>
                <div class = "divider"></div>
            </div>
        </div>
  );

}

export default Greenify;