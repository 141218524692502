import React, { useState } from 'react';
import './Table2.css';

function Table2() {
  const [isVisible, setIsVisible] = useState({
    one: false,
    two: false,
    three: false,
  });

  const toggleVisibility = (key) => {
    setIsVisible((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return (
    <div className="table">
      <div className="row">
        <div className="column">
          <div className="table-element" onClick={() => toggleVisibility('one')}>
            <p class = "italic-text">One. The current wound care procedure isn’t personalized beyond the clinic</p>
            <p className={`table-arrow ${isVisible.one ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.one ? 'visible' : ''}`}>
            <p className="table-hidden-text">
            During the site visit, we learned that the nurses often tailor verbal instructions to accommodate the needs of patients with wounds in various locations. However, the 4-page instruction sheet that patients take home is lengthy, difficult to navigate, and only holds generalized information, leading very few patients to refer to take-home instructions but instead call the clinic for clarification.
            </p>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="row">
        <div className="column">
          <div className="table-element" onClick={() => toggleVisibility('two')}>
            <p class = "italic-text">Two. Although patients show confidence for at-home wound care, they are unprepared when unexpected situations arise</p>
            <p className={`table-arrow ${isVisible.two ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.two ? 'visible' : ''}`}>
            <p className="table-hidden-text">
              From the patient interviews, we understood that they were leaving the clinic with high levels of confidence. At the same time however, we were hearing from the nurses that they were receiving numerous calls from patients who seemed to be seeking reassurance once they were home. There was a clear misalignment in patients’ perceptions of their confidence, and their actual wound care proficiency.
            </p>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="row">
        <div className="column">
          <div className="table-element" onClick={() => toggleVisibility('three')}>
            <p class = "italic-text">Three. Patients seek constant reassurance from direct conversations with trusted healthcare providers</p>
            <p className={`table-arrow ${isVisible.three ? 'rotated' : ''}`}>⌵</p>
          </div>
          <div className={`table-hidden ${isVisible.three ? 'visible' : ''}`}>
            <p className="table-hidden-text">
            We found that patients call the clinic not only for questions and clarifications, but also to seek reassurance. Both patients and nurses emphasized the importance of interacting through phone calls in interviews, but this preference for speaking with healthcare providers over turning to additional resources poses challenges, particularly in managing call volume, which becomes burdensome for nurses with limited time and resources.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table2;
