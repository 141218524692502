import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll} from 'react-scroll';
import './FocusPlanet.css';
import Function from './Function';
import heroImg from './assets/img/FocusPlanet/focusplanet-header-img.png';
import Image1 from './assets/img/FocusPlanet/timer.png';
import Image2 from './assets/img/FocusPlanet/home.png';
import Image3 from './assets/img/FocusPlanet/planetpass.png';
import Image4 from './assets/img/FocusPlanet/reward.png';
import Image5 from './assets/img/FocusPlanet/crew.png';
import Image6 from './assets/img/FocusPlanet/report.png';
import ChallengeImg from './assets/img/FocusPlanet/challenge-img.png'
import Solution1After from './assets/img/FocusPlanet/solution1-after.png';
import Solution1Before from './assets/img/FocusPlanet/solution1-before.png';
import Solution2 from './assets/video/solution2.mp4';
import Solution2Problem1 from './assets/img/FocusPlanet/solution2-problem1.png';
import Solution2Problem2 from './assets/img/FocusPlanet/solution2-problem2.png';
import Solution3Problem from './assets/img/FocusPlanet/solution3-problem.png';
import Solution3 from './assets/img/FocusPlanet/solution3.png';
import Solution3Impact from './assets/img/FocusPlanet/solution3-impact.png';
import FundingImg1 from './assets/img/FocusPlanet/funding-img1.png';
import FundingImg2 from './assets/img/FocusPlanet/funding-img2.png';
import BrandLogoDark from './assets/img/FocusPlanet/brand-identity-dark.png';
import BrandLogoLight from './assets/img/FocusPlanet/brand-identity-light.png';
import LogoVariations from './assets/img/FocusPlanet/logo-variations.png';
import BrandGuideDark from './assets/img/FocusPlanet/brand-guide-dark.png';
import BrandGuideLight from './assets/img/FocusPlanet/brand-guide-light.png';
import BoothImg from './assets/img/FocusPlanet/promo-booth-img.png';
import SNSImg from './assets/img/FocusPlanet/sns-img.png';


function FocusPlanet() {
  
    const [theme, setTheme] = useState('dark');
    const [atBottom, setAtBottom] = useState(false);
    const [activeSection, setActiveSection] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);
  
    const setLightMode = () => {
      setTheme('light');
    };
  
    const setDarkMode = () => {
      setTheme('dark');
    };
  
    useEffect(() => {
      const handleScroll = () => {
        const sections = ['#overview', '#introduction', '#challenge', '#solution', '#funding', '#marketing', '#branding', '#reflection'];
  
        let currentSection = '';
  
        sections.forEach(section => {
          const element = document.querySelector(section);
          const rect = element.getBoundingClientRect();
  
          // Detect when the section is in the viewport, at least partially visible
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            currentSection = section;
          } else if (rect.top < window.innerHeight && rect.bottom >= 0) {
            currentSection = section;
          }
        });
  
        setActiveSection(currentSection);
  
        // Check if user is at the bottom of the page
        if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
          setAtBottom(true);
        } else {
          setAtBottom(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      scroll.scrollToTop({
        duration: 400,
        smooth: 'easeInOutQuart',
      });
    };
  
    useEffect(() => {
      const hash = location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const offset = 40;
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    }, [location]);
  
    return (
      <div>
        <div class = "body">
            <div class = "main-content-hero">
              <div class = "hero-img">
                  <img src={heroImg} alt="Focus Planet Hero Image" />
              </div>
              <p class = "project-subtitle" id = "project-summary">Focus Planet</p>
              <h1 class = "project-title">Launching Focus Planet: <br></br>From Idea to a Startup</h1>
              <div class = "project-details">
                <p class = "project-description">Focus Planet is a reward-based study app that motivates users by converting every minute of study time into cashable rewards. As the co-founder and designer, I led the end-to-end design process, shaping the app’s user experience and brand identity. Through continuous iteration and user feedback, I enhanced the app's features to maximize user engagement and retention.</p>
                <div class = "project-tag-list">
                    <div class = "project-tag-row-1">
                        <div class = "project-tag-item" id = "timeline">
                            <p class = "tag-name">TIMELINE</p>
                            <p class = "tag-description">2+ years<br></br>(April 2022 – Present)</p>
                        </div>
                        <div class = "project-tag-item" id = "role">
                            <p class = "tag-name">ROLE</p>
                            <p class = "tag-description">Founder &amp;<br></br>Product Designer <br></br> Contributed to Product Strategy, Branding and Marketing </p>
                        </div>
                    </div>
                    <div class = "project-tag-row-2">
                        <div class = "project-tag-item" id = "collaborators">
                            <p class = "tag-name">COLLABORATORS</p>
                            <p class = "tag-description">1 Project Manager<br></br>2 Frontend Developers<br></br>1 Backend Developer</p>
                        </div>
                        <div class = "project-tag-item" id = "tools">
                            <p class = "tag-name">TOOLS</p>
                            <p class = "tag-description">Figma<br></br>Adobe Premiere Pro<br></br>Illustrator</p>
                        </div>
                    </div>
                    <div class = "project-tag-row-3" id = "button-wrapper">
                        <a href="https://www.focusplanet.app" target="_blank" rel="noopener noreferrer">
                            <button class = "button-text">Landing Page →</button>
                        </a>
                        <a href="https://apps.apple.com/kr/app/%ED%8F%AC%EC%BB%A4%EC%8A%A4%ED%94%8C%EB%9E%98%EB%8B%9B-%EB%8F%88%EB%B2%84%EB%8A%94-%EC%A7%91%EC%A4%91%EC%8B%9C%EA%B0%84-%EC%95%B1%ED%85%8C%ED%81%AC/id1597905981" target="_blank" rel="noopener noreferrer">
                            <button class = "button-text">Download App →</button>
                        </a>
                    </div>
                </div>
              </div>
            </div>
            <div class = "left-column">
                <nav>
                <ul class = {`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
                    <li class = {`left-column-list-text ${activeSection === '#overview' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#overview" smooth={true} duration={500}>
                            Overview
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#introduction' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#introduction" smooth={true} duration={500}>
                            Main Features
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#challenge' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#challenge" smooth={true} duration={500}>
                            Challenges
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#solution' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#solution" smooth={true} duration={500}>
                            Solutions
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#funding' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#funding" smooth={true} duration={500}>
                            Funding
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#marketing' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#marketing" smooth={true} duration={500}>
                            Marketing
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#branding' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#branding" smooth={true} duration={500}>
                            Branding
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#reflection' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#reflection" smooth={true} duration={500}>
                            Reflection
                        </Link>
                    </li>
                </ul>
                {atBottom && (
                    <ul class = "shortcut-menu show">
                    <li class = "left-column-list-text" onClick={scrollToTop} style={{ cursor: 'pointer' }}>
                        Go to top ↑
                    </li>
                    </ul>
                )}
                </nav>
                <div className="theme-toggle">
                <span
                    onClick={setDarkMode}
                    style={{
                    cursor: 'pointer',
                    fontWeight: theme === 'dark' ? 'bold' : 'normal',
                    color: theme === 'dark' ? 'inherit' : '#929292',
                    }}
                >
                    ⏾ DARK
                </span>
                <span style={{ margin: '0 10px' }}> / </span>
                <span
                    onClick={setLightMode}
                    style={{
                    cursor: 'pointer',
                    fontWeight: theme === 'light' ? 'bold' : 'normal',
                    color: theme === 'light' ? 'inherit' : '#929292',
                    }}
                >
                    ☼ LIGHT
                </span>
                </div>
            </div>
            <div class = "main-content">
              <section class = "content-section" id = "overview">
                  <p class = "content-tag">• Overview</p>
                  <div class = "section-content">
                      <p class = "section-subtitle">What is Focus Planet?</p>
                      <p class = "body-text">Focus Planet is a <span class="accent-text">reward-based study app</span> that <span class="accent-text">converts every minute of your study time into cashable rewards</span>, making it easier than ever to stay motivated and crush your goals.</p>
                  </div>
                  <p class = "content-tag" id = "my-role">• My Role</p>
                  <div class = "section-content">
                      <p class = "section-subtitle"> Co-Founder & Designer</p>
                      <p class = "body-text">As the co-founder and sole designer of the team, <span class="accent-text">I led the end-to-end design of the app</span>, shaping its overall vision and user experience. <span class="accent-text">I guided the product strategy and scoped features to align with user needs and business goals</span>, collaborating closely with the product manager. Additionally, working with a front-end and a back-end developer, <span class="accent-text">I organized detailed specifications and delivered designs optimized for seamless development.</span></p>
                      <p class = "body-text">To inform the design process, I <span class="accent-text">analyzed user behavior through app analytics, gathered feedback from in-app surveys, and conducted user interviews to identify pain points</span>. Through multiple iterations and the introduction of new features, I continually enhanced the user experience.</p>
                      <p class = "body-text">I <span class="accent-text">established a cohesive brand identity</span> that extended beyond the app interface to include printed marketing materials and a consistent social media presence.</p>
                  </div>
                  <p class = "content-tag" id = "impact">• Impact</p>
                  <div class = "section-content">
                      <div class = "metrics-box-wrapper">
                          <div class = "metrics-box-item">
                              <p class = "box-accent-text">$30K</p>
                              <div class = "divider"></div>
                              <p class = "box-text" id = "metrics-box-text">Acquired funding from the Korean Ministry of SMEs and Startups</p>
                          </div>
                          <div class = "metrics-box-item">
                              <p class = "box-accent-text">2700+</p>
                              <div class = "divider"></div>
                              <p class = "box-text" id = "metrics-box-text">Monthly Active Users <br></br>(2024.09-2024.10)</p>
                          </div>
                          <div class = "metrics-box-item">
                              <p class = "box-accent-text">#47</p>
                              <div class = "divider"></div>
                              <p class = "box-text" id = "metrics-box-text">Peak ranking in the Education Category on the Korean App Store</p>
                          </div>
                      </div>
                  </div>
              </section>
              <section class = "content-section" id = "introduction">
                  <p class = "content-tag">• Main Features</p>
                  <div class = "section-content">
                      <div class = "function-wrapper">
                          <Function />
                      </div>
                      <div class = "function-mobile">
                          <div class = "function-mobile-item">
                              <img class = "function-mobile-img" src = {Image1}/>
                              <p class = "box-accent-text" id = "function-mobile-title">Study Timer</p>
                              <p class = "body-text" id = "function-mobile-subtext">Users earn 1 star per minute when they track their focus time with Focus Planet.</p>
                          </div>
                          <div class = "function-mobile-item">
                              <img class = "function-mobile-img" src = {Image2}/>
                              <p class = "box-accent-text" id = "function-mobile-title">Home</p>
                              <p class = "body-text" id = "function-mobile-subtext">Users earn 1 star per minute when they track their focus time with Focus Planet.</p>
                          </div>
                          <div class = "function-mobile-item">
                              <img class = "function-mobile-img" src = {Image3}/>
                              <p class = "box-accent-text" id = "function-mobile-title">Planet Pass</p>
                              <p class = "body-text" id = "function-mobile-subtext">After 1 hour of continuous focus, users get a chance to enter a random bonus reward draw.</p>
                          </div>
                          <div class = "function-mobile-item">
                              <img class = "function-mobile-img" src = {Image4}/>
                              <p class = "box-accent-text" id = "function-mobile-title">Shop</p>
                              <p class = "body-text" id = "function-mobile-subtext">Points can be used in the reward shop to purchase gift cards, just like cash.</p>
                          </div>
                          <div class = "function-mobile-item">
                              <img class = "function-mobile-img" src = {Image5}/>
                              <p class = "box-accent-text" id = "function-mobile-title">Crew</p>
                              <p class = "body-text" id = "function-mobile-subtext">Users compete and achieve goals together with friends in study crews.</p>
                          </div>
                          <div class = "function-mobile-item">
                              <img class = "function-mobile-img" src = {Image6}/>
                              <p class = "box-accent-text" id = "function-mobile-title">Stats</p>
                              <p class = "body-text" id = "function-mobile-subtext">In the Stats tab, users can review their daily, weekly, and monthly study patterns.</p>
                          </div>
                      </div>
                  </div>
              </section>
              <section class = "content-section" id = "challenge">
                  <p class = "content-tag">• Challenges</p>
                  <div class = "section-content">
                    <div class = "challenge-content1">
                      <div class = "challenge-content1-text">
                        <p class = "section-subtitle">What isn’t working with our current app?</p>
                        <p class = "body-text">In order to see what was working and what was not working with our app, we looked into user behavior through <span class="accent-text">app analytics, receiving feedback from in-app surveys, and speaking directly with users</span>. While <span class="accent-text">study motivation, ease of use, and clean and engaging design</span> were commonly mentioned as what users loved about our app, there were some recurring themes that emerged as pain points:</p>
                      </div>
                      <img class = "challenge-img" src={ChallengeImg}/>
                    </div>
                    <div class = "challenge-content2">
                      <div id = "challenge-item">
                          <p class = "body-text" id = "challenge-text"><b>Repeated task creation</b></p>
                          <p class = "body-text">In our in-app survey, many users mentioned the <span class="accent-text">inconvenience of having to create the same tasks everyday</span>, because as students, they usually study similar subjects every day.</p>
                      </div>
                      <div id = "challenge-item">
                          <p class = "body-text" id = "challenge-text"><b>Accumulated stars</b></p>
                          <p class = "body-text">From looking into our user base data, we noticed that <span class="accent-text">a lot of stars were piling up without being converted into cash</span>, which was an undesirable behavior in terms of business goals. </p>
                      </div>
                      <div id = "challenge-item">
                          <p class = "body-text" id = "challenge-text"><b>Retention</b></p>
                          <p class = "body-text"><span class="accent-text">D+1 user retention was around 26.3%</span>, and we wanted to keep more users engaged and boost the retention rate.</p>
                      </div>
                    </div>
                  </div>
              </section>
              <section class = "content-section" id = "solution">
                <p class = "content-tag">• Solutions</p>
                <div class = "section-content">
                  <p class = "section-subtitle">One. Creating repeated tasks</p>
                  <div class = "solution1-problem">
                    <p class = "italic-text" id = "solution1-problem-left">Feedback. Users felt frustrated with having to create the same tasks every day.</p>
                    <p class = "body-text" id = "solution1-problem-right">In our in-app survey, we asked what users liked most and least about our app. We identified some recurring themes in their responses and focused on quickly improving usability for issues that could be easily addressed, one of which was the repetitive task creation process.</p>
                  </div>
                  <p class = "italic-text">Solution.</p>
                  <div class = "solution1">
                    <div class = "solution-before">
                      <img class = "solution1-img" src = {Solution1Before}/>
                      <div class = "solution-point">
                        <div class = "solution-point-left">
                          <div class = "solution-number" id = "solution-number-before">✕</div>
                        </div>
                        <div class = "soltuion-point-right">
                          <p class = "solution-point-title">Repetitive Task Creation Process</p>
                          <p class = "body-text" id = "solution-point-text">Users had to repeatedly go through the process of clicking the plus button, selecting "Add task," and inputting details, even when creating the same task every day.</p>
                        </div>
                      </div>
                    </div>
                    <div class = "solution-after">
                      <img class = "solution1-img" src = {Solution1After}/>
                      <div class = "solution-point">
                        <div class = "solution-point-left">
                          <div class = "solution-number" id = "solution-number-after">✓</div>
                        </div>
                        <div class = "solution-point-right">
                          <p class = "solution-point-title">Repeat task toggle</p>
                          <p class = "body-text" id = "solution-point-text">Users can enable the repeat task toggle to set a start and end date, allowing the corresponding task to be automatically repeated.</p>
                        </div>
                      </div>
                      <div class = "solution-point">
                        <div class = "solution-point-left">
                          <div class = "solution-number" id = "solution-number-after">✓</div>
                        </div>
                        <div class = "solution-point-right">
                          <p class = "solution-point-title">Indicate repeated tasks</p>
                          <p class = "body-text" id = "solution-point-text">When users create a repeated task, a pin icon will appear in the top left corner of the task box, indicating that the task is set to repeat. The task will remain visible on future dates with the pin icon displayed.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class = "section-subtitle">Two. Launching a lucky draw event</p>
                  <div class = "solution2-problem">
                    <div id = "solution2-problem-left">
                      <p class = "italic-text">Observation. Stars were piling up in user accounts.</p>
                      <p class = "body-text">To understand if user behavior aligned with our expectations, we analyzed user data, including the number of stars, cash balance, and study time. We found that many stars were piling up but not being converted into cash.</p>
                    </div>
                    <img id = "solution2-problem-right" src = {Solution2Problem1}/>
                  </div>
                  <div class = "solution2-problem">
                    <div id = "solution2-problem-left">
                      <p class = "italic-text">Hypothesis. Converting stars into cash required watching video ads, which users found bothering.</p>
                      <p class = "body-text">However, since watching ads was a core part of our business model, we couldn’t fundamentally change this process.</p>
                    </div>
                    <img id = "solution2-problem-right" src = {Solution2Problem2}/>
                  </div>
                  <div class = "solution2-solution">
                    <p class = "italic-text" id = "solution2-solution-left">Solution.</p>
                    <p class = "body-text" id = "solution2-solution-right">Therefore, I designed a way for users to spend their stars without undermining our profitability—a lucky draw event. The chances of winning gift cards was carefully calculated, based on the value of stars converted to cash for actual purchases.</p>
                  </div>
                  <div class = "solution2">
                    <video id = "solution2-video" autoPlay muted loop playsInline>
                      <source src= {Solution2} type="video/mp4" />
                    </video>
                  </div>
                  <div class = "solution2-impact">
                    <div class = "solution2-impact-box-wrapper" id = "solution2-impact-left">
                      <div class = "solution2-impact-box-item">
                          <p class = "box-accent-text">460</p>
                          <div class = "divider"></div>
                          <p class = "box-text" id = "metrics-box-text">Average clicks per day</p>
                      </div>
                      <div class = "solution2-impact-box-item">
                          <p class = "box-accent-text">75,935</p>
                          <div class = "divider"></div>
                          <p class = "box-text" id = "metrics-box-text">Average stars spent per day</p>
                      </div>
                    </div>
                    <div id = "solution2-impact-right">
                      <p class = "italic-text">Impact.</p>
                      <p class = "body-text">The lucky draw event was a big success, with users clicking it over <span class="accent-text">460 times per day</span> and spending <span class="accent-text">75,935 stars</span> daily (average of one week).</p>
                    </div>
                  </div>
                  <p class = "section-subtitle">Three. Introducing attendance missions</p>
                  <div class = "solution2-problem">
                    <div id = "solution2-problem-left">
                      <p class = "italic-text">Observation. The D+1 user retention rate was 26.3%, slightly lower than desirable.</p>
                      <p class = "body-text">Using app analytics, we tracked retention rates for D+1, D+7, and D+30 to monitor user engagement over time. We noticed room for improvement, particularly in retaining users beyond the first day, as maintaining higher retention rates is crucial for long-term app success.</p>
                    </div>
                    <img id = "solution2-problem-right" src = {Solution3Problem}/>
                  </div>
                  <div class = "solution2-solution">
                    <p class = "italic-text" id = "solution2-solution-left">Solution.</p>
                    <p class = "body-text" id = "solution2-solution-right">While thinking about how to keep users more engaged with our app, we decided to introduce a daily check-in mission. In line with the app's purpose of motivating study, I structured the mission so that users will earn tiered rewards based on their study time: 1 hour for 3 cash, 3 hours for 5 cash, 5 hours for 7 cash, and 7 hours for 10 cash.</p>
                  </div>
                  <div class = "solution3">
                    <img class = "solution3-img" src = {Solution3}/>
                  </div>
                  <div class = "solution3-impact">
                    <img id = "solution3-impact-left" src = {Solution3Impact}/>
                    <div id = "solution3-impact-right">
                      <p class = "italic-text">Impact.</p>
                      <p class = "body-text">The introduction of the daily check-in <span class="accent-text">boosted D+1 retention from 26.3% (September 2023) to 40.7% (November 2023)</span>, highlighting its significant impact.</p>
                    </div>
                  </div>
                </div>
              </section>
              <section class = "content-section" id = "funding">
                  <p class = "content-tag">• Funding</p>
                  <div class = "section-content">
                    <div class = "funding1">
                      <div id = "funding1-left">
                        <p class = "section-subtitle">Crowdfunding branded goods</p>
                        <p class = "body-text">One of the most persistent challenges we faced in our journey was securing funding. In the early stages, when our app was barely generating any revenue, our initial approach was <span class="accent-text">selling space-themed paperweights on a crowdfunding site called Tumblebug, which helped us raise around $2,000 in profit</span>. However, we quickly realized that relying on merchandise sales wasn’t sustainable in the long run.</p>
                      </div>
                      <img id = "funding1-right" src = {FundingImg1}/>
                    </div>
                    <div class = "funding2">
                      <img id = "funding2-left" src = {FundingImg2}/>
                      <div id = "funding2-right">
                        <p class = "section-subtitle">Securing government funding</p>
                        <p class = "body-text">Then, we shifted our focus to government-funded opportunities, and applied for a Pre-Startup Package. After a screening process that included submitting a business proposal and delivering a pitch, we were selected and <span class="accent-text">received a total of $30K in funding from the Ministry of SMEs and Startups</span> from May to December, 2023. This helped us cover our operational costs and allowed us to explore new opportunities, like running online marketing campaigns. <span class="accent-text">In June 2023, we also took a significant step by officially registering our business.</span></p>
                      </div>
                    </div>
                  </div>
              </section>
              <section class = "content-section" id = "marketing">
                  <p class = "content-tag">• Marketing</p>
                  <div class = "section-content">
                    <p class = "body-text"><span class="accent-text">When we launched our app, we quickly realized that getting it on the App Store was just the first step. Without a dedicated marketing role on our team, driving customer engagement became one of our major challenges.</span></p>
                    <div class = "marketing1">
                      <p class = "section-subtitle" id = "marketing1-left">Offline. Running promotional booths</p>
                      <p class = "body-text" id = "marketing1-right">Initially, we focused on offline promotional events, like running promotional booths. We had opportunities to introduce our product at <span class="accent-text">university fairs, visiting Kyunghee University and Yonsei University</span>. To draw in the crowd, we handed out glow stick bracelets to anyone who downloaded the app, and the response was very positive. <br></br><br></br>Later, we landed spots at larger gatherings like the <span class="accent-text">Sinchon Global Culture Festival and Insiders Demo Day</span>. There, we introduced lucky draw events, where people could earn a chance to win custom merch, like sticky notes, phone grips, and paperweights if they downloaded the app.</p>
                    </div>
                    <img class = "marketing-img" src = {BoothImg}/>
                    <div class = "marketing2">
                      <p class = "section-subtitle" id = "marketing2-left">Online. Social media marketing</p>
                      <p class = "body-text" id = "marketing2-right">Without a professional marketer on the team, the product manager and I decided to try our hands at social media marketing. <span class="accent-text">Our primary users were women in their teens and twenties</span>, so we focused on platforms like as Instagram, Facebook, and TikTok. <br></br><br></br>We created relatable study motivation contents and visually engaging advertisements that resonated with this demographic, and to our surprise, it worked far better than we’d anticipated. <span class="accent-text">Our campaigns attracted traffic to our landing page at as low as just $0.10 per visit.</span></p>
                    </div>
                    <img class = "marketing-img" src = {SNSImg}/>
                  </div>
              </section>
              <section class = "content-section" id = "branding">
                  <p class = "content-tag">• Branding</p>
                  <div class = "section-content">
                    <p class = "body-text"><span class="accent-text">As we shifted to the social media marketing stage, I led a complete rebranding to improve Search Engine Optimization (SEO) and establish a cohesive brand identity across the app interface, printed materials, and social media. The minimalist design emphasizes focus and clarity, aligning with the Focus Planet’s mission to motivate students by delivering a truly "rewarding" study experience. Its dark, space-themed aesthetic, with calming shades of blue, also reduces battery consumption, ensuring energy efficiency for prolonged screen use.</span></p>
                    <p class = "image-subtext" id = "logo-image-subtext">Logo</p>
                    <img class = "branding-img" src={theme === 'light' ? BrandLogoLight : BrandLogoDark}/>
                    <p class = "image-subtext">Logo Variations</p>
                    <img class = "branding-img" src = {LogoVariations}/>
                    <p class = "image-subtext">Brand Guidelines</p>
                    <img class = "branding-img" src={theme === 'light' ? BrandGuideLight : BrandGuideDark}/>
                  </div>
              </section>
              <section class = "content-section" id = "reflection">
                  <p class = "content-tag">• Reflection</p>
                  <div class = "section-content">
                    <div class = "reflection1">
                      <p class = "section-subtitle" id = "reflection1-left">Balancing user needs with feasibility and business goals</p>
                      <p class = "body-text" id = "reflection1-right">Although advocating for users is fundamental to my approach, I believe I have also learned to <span class="accent-text">balance user needs with feasibility and business goals through</span> this experience. Countless meetings reviewing feasibility with developers and debating the tradeoff between profitability user experience with the product manager allowed me to grow into <span class="accent-text">a designer with a systems-level perspective.</span></p>
                    </div>
                    <div class = "reflection2">
                      <p class = "section-subtitle" id = "reflection2-left">Directly engaging with end users, iterating, and being flexible</p>
                      <p class = "body-text" id = "reflection2-right">During 2+ years building and operating a mobile app from scratch, I quickly learned that launching a product is just the first step. It was through <span class="accent-text">relentless iteration and many experiments</span> that we were able to improve. This experience helped me develop <span class="accent-text">adaptability and problem-solving skills</span> that only come from working in a startup environment, meeting challenges - whether technical, financial, or strategic, every day.</p>
                    </div>
                  </div>
              </section>
            </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                        <p class = "project-footer-subtitle">MHCI X University Hospitals of Cleveland</p>
                        <p class = "project-footer-title">Bridging the Gap Between Clinic & At-home Wound Care</p>
                        <button class = "read-more-button" onClick={() => navigate('/uhc')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                      <p class = "project-footer-subtitle">Senior Thesis</p>
                      <p class = "project-footer-title">Reducing Seat-Finding Frustrations in the Subway</p>
                      <button class = "read-more-button" onClick={() => navigate('/subway')}>Read More →</button>
                    </div>
                </div>
            <div class = "divider"></div>
            </div>
        </div>
    );

}

export default FocusPlanet;