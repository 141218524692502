import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll} from 'react-scroll';
import './UHC.css';
import Table2 from './Table2.js';
import heroImg from './assets/img/UHC/uhc-header-img.png';
import Method1Img from './assets/img/UHC/method1-img.png';
import Method2Img from './assets/img/UHC/method2-img.png';
import Method3Img from './assets/img/UHC/method3-img.png';
import Method4Img from './assets/img/UHC/method4-img.png';
import Synthesis1 from './assets/img/UHC/synthesis1.png';
import Synthesis2 from './assets/img/UHC/synthesis2.png';
import Synthesis3 from './assets/img/UHC/synthesis3.png';
import Synthesis4 from './assets/img/UHC/synthesis4.png';
import Circle1 from './assets/img/UHC/circle1.png';
import Circle2 from './assets/img/UHC/circle2.png';
import Circle3 from './assets/img/UHC/circle3.png';
import RedesignImg from './assets/img/UHC/redesign-img.png';
import DesignDeepDive from './assets/img/UHC/design-deep-dive.png';
import CuraImg from './assets/img/UHC/cura-img.png';
import CuraAICall from './assets/img/UHC/cura-ai-call.png';
import CuraInfo from './assets/img/UHC/cura-wound-care-info.png';
import VisitImg from './assets/img/UHC/visit-img.png';
import Iteration1 from './assets/img/UHC/iteration1.png';
import Iteration2 from './assets/img/UHC/iteration2.png';
import Iteration3 from './assets/img/UHC/iteration3.png';
import Testing1 from './assets/img/UHC/testing1.png';


function UHC() {
  
    const [theme, setTheme] = useState('dark');
    const [atBottom, setAtBottom] = useState(false);
    const [activeSection, setActiveSection] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);
  
    const setLightMode = () => {
      setTheme('light');
    };
  
    const setDarkMode = () => {
      setTheme('dark');
    };
  
    useEffect(() => {
      const handleScroll = () => {
        const sections = ['#overview', '#research', '#synthesis', '#solution', '#iteration', '#testing', '#reflection'];
  
        let currentSection = '';
  
        sections.forEach(section => {
          const element = document.querySelector(section);
          const rect = element.getBoundingClientRect();
  
          // Detect when the section is in the viewport, at least partially visible
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            currentSection = section;
          } else if (rect.top < window.innerHeight && rect.bottom >= 0) {
            currentSection = section;
          }
        });
  
        setActiveSection(currentSection);
  
        // Check if user is at the bottom of the page
        if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
          setAtBottom(true);
        } else {
          setAtBottom(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      scroll.scrollToTop({
        duration: 400,
        smooth: 'easeInOutQuart',
      });
    };
  
    useEffect(() => {
      const hash = location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const offset = 40;
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    }, [location]);
  
    return (
      <div>
        <div class = "body">
            <div class = "main-content-hero">
              <div class = "hero-img">
                  <img src={heroImg} alt="CMU MHCI X University Hospitals of Cleveland Hero Image" />
              </div>
              <p class = "project-subtitle" id = "project-summary">CMU MHCI X University Hospitals of Cleveland</p>
              <h1 class = "project-title">Bridging the Gap Between Clinic &amp; At-home Wound Care</h1>
              <div class = "project-details">
                <p class = "project-description">As part of our Master’s in Human-Computer Interaction capstone project, our team collaborated with University Hospitals of Cleveland to improve outcomes for patient-administered wound care following Mohs surgery. We redesigned the at-home wound care instruction sheet and prototyped Cura, an AI-powered wound care assistant.</p>
                <div class = "project-tag-list">
                    <div class = "project-tag-row-1">
                        <div class = "project-tag-item" id = "timeline">
                            <p class = "tag-name">TIMELINE</p>
                            <p class = "tag-description">7 months <br></br>(January 2024 – July 2024)</p>
                        </div>
                        <div class = "project-tag-item" id = "role">
                            <p class = "tag-name">ROLE</p>
                            <p class = "tag-description">User Experience Designer</p>
                        </div>
                    </div>
                    <div class = "project-tag-row-2">
                        <div class = "project-tag-item" id = "collaborators">
                            <p class = "tag-name">COLLABORATORS</p>
                            <p class = "tag-description">Tina Chen <br></br> Sophie Davis <br></br> Rachel Sadeh <br></br> Joanne Yang</p>
                        </div>
                        <div class = "project-tag-item" id = "tools">
                            <p class = "tag-name">TOOLS</p>
                            <p class = "tag-description">Figma / <br></br> Empathy Study <br></br> Field Research <br></br> Affinity Diagramming <br></br> Speed Dating <br></br> Co-Creation Workshop <br></br> Rapid Prototyping <br></br>Usability Testing</p>
                        </div>
                    </div>
                    <div class = "project-tag-row-3" id = "button-wrapper">
                        <a href="https://medium.com/team-uh-cleveland" target="_blank" rel="noopener noreferrer">
                            <button class = "button-text">Medium Posts →</button>
                        </a>
                        <a href="https://www.figma.com/proto/4FF3xQ0E8BR729stQCjYJA/Sustainable-Design?page-id=22%3A208&type=design&node-id=25-782&viewport=205%2C28%2C0.14&t=pVq9XRoJf3sbN2Ge-1&scaling=min-zoom&starting-point-node-id=25%3A782&mode=design" target="_blank" rel="noopener noreferrer">
                            <button class = "button-text">Prototype →</button>
                        </a>
                    </div>
                </div>
              </div>
            </div>
            <div class = "left-column">
                <nav>
                <ul class = {`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
                    <li class = {`left-column-list-text ${activeSection === '#overview' ? 'active' : ''}`}>
                        <Link to = "/uhc#overview" smooth={true} duration={500}>
                            Overview
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#research' ? 'active' : ''}`}>
                        <Link to = "/uhc#research" smooth={true} duration={500}>
                            Research
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#synthesis' ? 'active' : ''}`}>
                        <Link to = "/uhc#synthesis" smooth={true} duration={500}>
                            Synthesis
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#solution' ? 'active' : ''}`}>
                        <Link to = "/uhc#solution" smooth={true} duration={500}>
                            Solution
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#iteration' ? 'active' : ''}`}>
                        <Link to = "/uhc#iteration" smooth={true} duration={500}>
                            Iteration
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#testing' ? 'active' : ''}`}>
                        <Link to = "/uhc#testing" smooth={true} duration={500}>
                            Testing
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#reflection' ? 'active' : ''}`}>
                        <Link to = "/uhc#reflection" smooth={true} duration={500}>
                            Reflection
                        </Link>
                    </li>
                </ul>
                {atBottom && (
                    <ul class = "shortcut-menu show">
                    <li class = "left-column-list-text" onClick={scrollToTop} style={{ cursor: 'pointer' }}>
                        Go to top ↑
                    </li>
                    </ul>
                )}
                </nav>
                <div className="theme-toggle">
                <span
                    onClick={setDarkMode}
                    style={{
                    cursor: 'pointer',
                    fontWeight: theme === 'dark' ? 'bold' : 'normal',
                    color: theme === 'dark' ? 'inherit' : '#929292',
                    }}
                >
                    ⏾ DARK
                </span>
                <span style={{ margin: '0 10px' }}> / </span>
                <span
                    onClick={setLightMode}
                    style={{
                    cursor: 'pointer',
                    fontWeight: theme === 'light' ? 'bold' : 'normal',
                    color: theme === 'light' ? 'inherit' : '#929292',
                    }}
                >
                    ☼ LIGHT
                </span>
                </div>
            </div>
            <div class = "main-content">
              <section class = "content-section" id = "overview">
                  <p class = "content-tag">• Overview</p>
                  <div class = "section-content">
                      <p class = "section-subtitle">Project Scope</p>
                      <p class = "body-text">Working alongside University Hospitals of Cleveland, our group of Masters in Human-Computer Interaction students were tasked with <span class="accent-text">improving outcomes for patient-administered wound care following Mohs surgery, with a service that bridges clinic and home</span>.</p>
                      <p class = "body-text"><a href="https://www.youtube.com/watch?v=F6TxdvjPk5I" id = "mohs-surgery-link" target="_blank">Mohs surgery↗</a> is essentially a procedure that involves removing the cancer tissues layer by layer. Following Mohs surgery, patients receive both written and verbal instructions and are <span class="accent-text">sent home with medical supplies to manage their care, including performing daily dressing changes for a set period.</span></p>
                  </div>
                  <p class = "content-tag" id = "my-role">• My Role</p>
                  <div class = "section-content">
                      <p class = "section-subtitle"> User Experience Designer</p>
                      <p class = "body-text">As a User Experience Designer in a team of five, I played a key role in:</p>
                      <p class = "body-text"><span class="accent-text">* Conducting interviews and empathy studies with patients and healthcare providers.<br></br>* Leading the re-design of 4-page instruction sheet.<br></br>* Designing and iterating on prototypes in Figma based on usability testing results.<br></br>* Collaborating with team members and stakeholders to ensure practical, empathetic, and impactful solutions.</span></p>
                  </div>
                  <p class = "content-tag" id = "impact">• Impact</p>
                  <div class = "section-content">
                      <div class = "metrics-box-wrapper">
                          <div class = "metrics-box-item">
                              <p class = "box-accent-text">80%</p>
                              <div class = "divider"></div>
                              <p class = "box-text" id = "metrics-box-text">Preferred the redesign over current design</p>
                          </div>
                          <div class = "metrics-box-item">
                              <p class = "box-accent-text">35%↑</p>
                              <div class = "divider"></div>
                              <p class = "box-text" id = "metrics-box-text">Increase in reported confidence in post-surgical care</p>
                          </div>
                          <div class = "metrics-box-item">
                              <p class = "box-accent-text">4.75</p>
                              <div class = "divider"></div>
                              <p class = "box-text" id = "metrics-box-text">Perceived level of helpfulness<br></br>(scale of 5)</p>
                          </div>
                      </div>
                  </div>
              </section>
              <section class = "content-section" id = "research">
                  <p class = "content-tag">• Research</p>
                  <div class = "section-content">
                    <p class = "section-subtitle">What is the problem?</p>
                    <p class = "body-text"><span class="accent-text">While we were tasked with improving outcomes for patient-administered wound care, the problem statement was vague and broad. We first had to see uncover key pain points and clarified the problem through user research. Various methods were used, encompassing a 1-week empathy study, a site visit and patient interviews, expert interviews, and speed dating.</span></p>
                    {/*<img id = "patient-journey-map-img" src={theme === 'light' ? patientJourneyMapLightMode : patientJourneyMapDarkMode} alt="Patient Journey Map" />*/}
                    <div class = "solution2-solution" id = "method1">
                        <p class = "italic-text" id = "solution2-solution-left">Method One: Step into the Patient’s Shoes</p>
                        <p class = "body-text" id = "solution2-solution-right">During our kickoff meeting with clients, <span class="accent-text">we were each assigned a wound to care for - the eye, in my case — </span> and followed at-home procedures for a week. This hands-on experience, as well as keeping diaries and sharing challenges, gave us valuable insights into the real-world difficulties patients face during recovery.</p>
                    </div>
                    <div class = "solution3">
                        <img class = "solution3-img" src = {Method1Img}/>
                    </div>
                    <div class = "solution2-solution" id = "method2">
                        <p class = "italic-text" id = "solution2-solution-left">Method Two: Interact with actual patients</p>
                        <p class = "body-text" id = "solution2-solution-right">We had an opportunity to visit the University Hospitals and <span class="accent-text">speak with seven patients and do a mock-up with the doctor and nurses</span>. In the mockup session, the nurses and the doctor recreated the exact experience that a patient would go through following their surgery — dressing their wounds, listening to the at-home wound care instructions and receiving a four-page instruction sheet about what to do.</p>
                    </div>
                    <div class = "solution3">
                        <img class = "solution3-img" src = {Method2Img}/>
                    </div>
                    <div class = "solution2-solution" id = "method3">
                        <p class = "italic-text" id = "solution2-solution-left">Method Three: Learn from experts</p>
                        <p class = "body-text" id = "solution2-solution-right">We <span class="accent-text">recruited 6 nurses from the University Hospitals of Cleveland, and 2 nurses and 1 dermatologist from University of Pittsburgh Medical Center</span>, to understand more about wound care practices, and pain points from the perspective of healthcare providers.</p>
                    </div>
                    <div class = "solution3">
                        <img class = "solution3-img" src = {Method3Img}/>
                    </div>
                    <div class = "solution2-solution" id = "method4">
                        <p class = "italic-text" id = "solution2-solution-left">Method Four. Speed date design ideas with patients</p>
                        <p class = "body-text" id = "solution2-solution-right">Based on user needs identified in our research, we <span class="accent-text">generated 40+ solution ideas and refined them to 9 final concepts for speed dating with 9 Mohs surgery patients</span> through interviews.</p>
                    </div>
                    <div class = "solution3">
                        <img class = "solution3-img" src = {Method4Img}/>
                    </div>
                  </div>
              </section>
              <section class = "content-section" id = "synthesis">
                <p class = "content-tag">• Synthesis</p>
                <div class = "section-content">
                    <div class = "synthesis">
                        <div class = "synthesis-left">
                            <p class = "section-subtitle">Synthesizing findings and gathering insights</p>
                            <p class = "body-text">While gathering lots of data from user research, we synthesized the findings through methods like walking the wall and affinity diagramming. To better understand the current context and user experience, we created various design artifacts, including a stakeholder map, patient journey map, and archetypes. This allowed us to uncover key insights and establish a framework that later guided our design process.</p>
                        </div>
                        <div class = "synthesis-right">
                            <div class = "synthesis-right-row">
                                <img class = "synthesis-img" src = {Synthesis1}/>
                                <img class = "synthesis-img" src = {Synthesis2}/>
                            </div>
                            <div class = "synthesis-right-row">
                                <img class = "synthesis-img" src = {Synthesis3}/>
                                <img class = "synthesis-img" src = {Synthesis4}/>
                            </div>
                        </div>
                    </div>
                </div>
                <p class = "section-subtitle">Key Insights.</p>
                <Table2 />
                <p class = "section-subtitle">Framework.</p>
                <p class = "body-text"><span class="accent-text">We developed a framework emphasizing the need for a system of solutions that combines proactive and reactive approaches to enhance the overall patient experience. The proactive approach aims to prepare patients for potential obstacles in their post-surgical care, while the reactive approach ensures they remain calm and develop problem-solving skills when issues arise, reducing over-reliance on healthcare providers. <br></br><br></br>This dual approach creates a virtuous cycle: proactive solutions minimize the likelihood of problems through best practices, and reactive solutions provide accessible next steps, immediate answers, and reassurance when unexpected situations occur.</span></p>
                <div class = "framework">
                    <div class = "framework-left">
                        <p class = "italic-text" id = "framework-left-text"> <b>Proactive</b></p>
                        <p class = "body-text" id = "framework-left-text">Preparation for post-surgical care for patients at the clinic</p>
                    </div>
                    <div class = "framework-center">
                        <img class = "framework-img" src={Circle1} />
                    </div>
                    <div class = "framework-right">
                        <p class = "italic-text" id = "framework-right-text"> <b>Reactive</b></p>
                        <p class = "body-text" id = "framework-right-text">Reassurance and immediate answers once patients get home</p>
                    </div>
                </div>
                <p class = "section-subtitle">How Might We Statements.</p>
                <p class = "italic-text" id = "hmw1">01 How might we personalize wound-care materials to increase patient trust and attention?</p>
                <div class = "framework">
                    <div class = "framework-left">
                        <p class = "italic-text" id = "framework-left-text"> <b>Proactive</b></p>
                        <p class = "body-text" id = "framework-left-text">Preparation for post-surgical care for patients at the clinic</p>
                    </div>
                    <div class = "framework-center">
                        <img class = "framework-img" src={Circle2} />
                    </div>
                    <div class = "framework-right">
                        <p class = "italic-text" id = "hmw1-right-text"> <b>Reactive</b></p>
                        <p class = "body-text" id = "hmw1-right-text">Reassurance and immediate answers once patients get home</p>
                    </div>
                </div>
                <p class = "italic-text" id = "hmw2">02 How might we ensure patients receive immediate answers and reassurance while reducing the workload for nurses?</p>
                <div class = "framework">
                    <div class = "framework-left">
                        <p class = "italic-text" id = "hmw2-left-text"> <b>Proactive</b></p>
                        <p class = "body-text" id = "hmw2-left-text">Preparation for post-surgical care for patients at the clinic</p>
                    </div>
                    <div class = "framework-center">
                        <img class = "framework-img" src={Circle3} />
                    </div>
                    <div class = "framework-right">
                        <p class = "italic-text" id = "framework-right-text"> <b>Reactive</b></p>
                        <p class = "body-text" id = "framework-right-text">Reassurance and immediate answers once patients get home</p>
                    </div>
                </div>
              </section>
              <section class = "content-section" id = "solution">
                <p class = "content-tag">• Solution</p>
                <div class = "section-content">
                    <p class = "section-subtitle" id = "solution-heading">Watch a demo of how our system of solutions work together</p>
                    <iframe 
                        id = "demo-video"
                        src="https://www.youtube.com/embed/vu7T_YepYD4?autoplay=0" 
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowfullscreen>
                    </iframe>
                    <div class = "challenge-content1" id = "redesign">
                      <div class = "challenge-content1-text">
                        <p class = "section-subtitle">Proactive Solution. Re-designed Instruction Sheets</p>
                        <p class = "body-text">The redesigned instruction sheet is clear, concise, and easy to navigate. Not only did we condense and reorganize information to <span class="accent-text">enhance navigability</span>, we also incorporated <span class="accent-text">illustrations and color</span> for visual learners. <br></br><br></br>More importantly, we dedicated the last page to <span class="accent-text">location-specific information</span>, highlighting precautions and do’s and don’ts for specific wound locations. The nurses can simply pull up a pre-printed sheet relevant to the patient, not adding too much burden to their everyday work.</p>
                      </div>
                      <img class = "challenge-img" src={RedesignImg}/>
                    </div>
                    <div class = "solution2-solution" id = "design-deep-dive">
                        <p class = "italic-text" id = "solution2-solution-left">Design Deep Dive.</p>
                        <p class = "body-text" id = "solution2-solution-right">The current instruction sheet had problems in that it was not personalized, lengthy and full of words, and difficult to navigate. I led the re-design of the instruction sheet, focusing on reorganizing information and incorporating image-based visuals.</p>
                    </div>
                    <div class = "solution3">
                        <img class = "solution3-img" src = {DesignDeepDive}/>
                    </div>
                    <div class = "challenge-content1" id = "cura">
                      <img class = "challenge-img" src={CuraImg}/>
                      <div class = "challenge-content1-text">
                        <p class = "section-subtitle" id = "cura-text">Reactive Solution. The Cura App</p>
                        <p class = "body-text" id = "cura-text">Cura is a mobile application that provides patients with <span class="accent-text">immediate access to answers and reassurance</span>, helping them stay informed and confident in <span class="accent-text">navigating unexpected problems independently.</span></p>
                      </div>
                    </div>
                    <div class = "solution2-solution" id = "design-deep-dive">
                        <p class = "italic-text" id = "solution2-solution-left">Design Deep Dive.</p>
                        <p class = "body-text" id = "solution2-solution-right">The current instruction sheet had problems in that it was not personalized, lengthy and full of words, and difficult to navigate. I led the re-design of the instruction sheet, focusing on reorganizing information and incorporating image-based visuals.</p>
                    </div>
                    <div class = "solution3">
                        <img class = "solution3-img" src = {CuraAICall}/>
                    </div>
                    <div class = "solution3" id = "cura-info">
                        <img class = "solution3-img" src = {CuraInfo}/>
                    </div>
                </div>
              </section>
              <section class = "content-section" id = "iteration">
                <p class = "content-tag">• Iteration</p>
                <div class = "section-content">
                <p class = "section-subtitle">How do the patients receive AI-based solutions?</p>
                  <div class = "solution2-problem">
                    <div id = "solution2-problem-left">
                      <p class = "italic-text">Testing with patients and seniors</p>
                      <p class = "body-text">In the design process, we <span class="accent-text">rapidly prototyped over 10 ideas, iterating through multiple rounds of testing</span> to validate our hypotheses and gather user feedback. In addition to visiting Cleveland, we also <span class="accent-text">contacted and visited senior centers in Pittsburgh</span> to understand how seniors (over the age of 65) may perceive AI-based solutions.</p>
                    </div>
                    <img id = "solution2-problem-right" src = {VisitImg}/>
                  </div>
                  <p class = "italic-text">Making AI approachable and familiar</p>
                  <p class = "body-text">The main issue highlighted during our mid-fi testing was <span class="accent-text">“trust”</span>. Although 34% of participants felt comfortable interacting with AI during testing, <span class="accent-text">none were willing to choose it as a viable solution in real-life situations</span>. To address this , we developed the following strategies to enhance our use of AI.</p>
                  <div class = "iteration">
                    <div class = "iteration-top">
                        <img class = "iteration-img" src = {Iteration1}/>
                        <img class = "iteration-img" src = {Iteration2}/>
                    </div>
                    <img class = "iteration-img2" src = {Iteration3}/>
                  </div>
                </div>
              </section>
              <section class = "content-section" id = "testing">
                <p class = "content-tag">• Testing</p>
                <div class = "section-content">
                    <div class = "solution2-problem" id = "testing1">
                        <div id = "solution2-problem-left">
                        <p class = "section-subtitle">Current vs. Redesigned Instruction Sheet</p>
                        <p class = "body-text">To evaluate the effectiveness of the redesigned instruction sheet, we <span class="accent-text">tested with 10 patients and senior center participants</span> and assigned users comparable tasks (with variations in order), <span class="accent-text">measuring the task completion rate and time on task</span>. We also asked questions asking their confidence level navigating the information, perceived helpfulness and preference between the two options. The results are summarized below.</p>
                        </div>
                        <img id = "solution2-problem-right" src = {Testing1}/>
                    </div>
                    <div class = "metrics-box-wrapper" id = "testing-metrics">
                          <div class = "metrics-box-item">
                              <p class = "box-accent-text">35%↑</p>
                              <div class = "divider"></div>
                              <p class = "box-text" id = "metrics-box-text">Increase in confidence in post-surgical care</p>
                          </div>
                          <div class = "metrics-box-item">
                              <p class = "box-accent-text">4.75</p>
                              <div class = "divider"></div>
                              <p class = "box-text" id = "metrics-box-text">Perceived level of helpfulness<br></br>(scale of 5)</p>
                          </div>
                          <div class = "metrics-box-item">
                              <p class = "box-accent-text">8 of 10</p>
                              <div class = "divider"></div>
                              <p class = "box-text" id = "metrics-box-text">Preferred the re-designed instruction sheet to the current version</p>
                          </div>
                      </div>
                </div>
              </section>
              <section class = "content-section" id = "reflection">
                  <p class = "content-tag">• Reflection</p>
                  <div class = "section-content">
                    <div class = "reflection1">
                      <p class = "section-subtitle" id = "reflection1-left">Navigating ambiguity</p>
                      <p class = "body-text" id = "reflection1-right">Mohs surgery was a completely new field for me, and <span class="accent-text">the problem statement we started with was vague and broad</span>. As we dove deeper into research, there were also moments when we realized we had to shift direction from what we initially thought was the problem. However, through this process, I believe <span class="accent-text">I learned how to navigate ambiguity—exploring, pivoting, and refining ideas to uncover the core problem</span>. It was challenging but incredibly meaningful.</p>
                    </div>
                    <div class = "reflection2">
                      <p class = "section-subtitle" id = "reflection2-left">Being on foot</p>
                      <p class = "body-text" id = "reflection2-right">For this project, I needed to connect with people I don’t typically meet—patients, doctors, and seniors. While I am incredibly grateful for UHC’s support in recruiting participants, <span class="accent-text">my team and I also took very proactive approaches</span> such as contacting and visiting senior centers, which taught us things we could never have learned from our desks. <span class="accent-text">Empathy is at the heart of user experience design</span>, and in this project, it was key to our success. It was only possible because <span class="accent-text">we stepped out into the real world to truly connect with people</span>.</p>
                    </div>
                    <div class = "reflection3">
                      <p class = "section-subtitle" id = "reflection3-left">Validation through testing</p>
                      <p class = "body-text" id = "reflection3-right">From the earliest stages of low-fidelity prototypes to more polished iterations, <span class="accent-text">our team conducted extensive rounds of testing</span>. Each iteration brought new insights as we observed users interact with the designs and gathered feedback. This process was invaluable <span class="accent-text">not only for refining the product but also for challenging our initial assumptions</span>. This iterative approach <span class="accent-text">reinforced my belief in the importance of being open to change and letting user insights guide the design process</span>.</p>
                    </div>
                  </div>
              </section>
            </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                        <p class = "project-footer-subtitle">MHCI X University Hospitals of Cleveland</p>
                        <p class = "project-footer-title">Bridging the Gap Between Clinic & At-home Wound Care</p>
                        <button class = "read-more-button" onClick={() => navigate('/uhc')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                      <p class = "project-footer-subtitle">Focus Planet</p>
                      <p class = "project-footer-title">Launching Focus Planet: <br></br>From Idea to a Startup</p>
                      <button class = "read-more-button" onClick={() => navigate('/focusplanet')}>Read More →</button>
                    </div>
                </div>
            <div class = "divider"></div>
            </div>
        </div>
    );

}

export default UHC;